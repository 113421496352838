<template>
  <v-app>
    <dashboard-core-drawer
      v-model="expandOnHover"
      :expand-on-hover.sync="expandOnHover"
    />
    <dashboard-core-view/>
  </v-app>
</template>

<script>
  export default {
    name: 'DashboardIndex',

    components: {
      DashboardCoreDrawer: () => import('./components/core/DrawerLeft'),
      // DashboardCoreDrawer: () => import('./composable/core/Drawer'),
      DashboardCoreView: () => import('./components/core/View'),
    },

    data: () => ({
      expandOnHover: false,
    }),
  }
</script>
